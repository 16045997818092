"use client";

import CookieConsent from "react-cookie-consent";
import Link from "next/link";

const CustomCookieConsent = () => {
  return (
    <CookieConsent
      location="bottom"
      buttonText="Got it!"
      cookieName="siteCookieConsent"
      containerClasses="bg-gray-500 text-white"
      buttonStyle={{
        backgroundColor: "#595959",
        color: "white",
        paddingLeft: "1rem",
        paddingRight: "1rem",
        borderRadius: "0.25rem",
        marginLeft: "1rem",
      }}
      expires={365}
    >
      We use cookies to ensure you get the best experience on our website.{" "}
      <Link
        href="/policies/privacy-policy"
        className="hover:text-main underline"
      >
        Learn more
      </Link>
    </CookieConsent>
  );
};

export default CustomCookieConsent;
